import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "100",
  height: "100",
  viewBox: "0 0 100 100",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<title>search</title><g filter=\"url(#__filter0_b_5162_10628)\"><circle cx=\"50\" cy=\"50\" r=\"50\" fill-opacity=\"0.4\"></circle></g><g clip-path=\"url(#__clip0_5162_10628)\"><path d=\"M44.7152 38.5429C43.3826 37.7228 41.667 38.6815 41.667 40.2462V59.7546C41.667 61.3192 43.3826 62.2779 44.7152 61.4579L60.5658 51.7037C61.8348 50.9227 61.8348 49.078 60.5658 48.2971L44.7152 38.5429Z\" fill=\"currentColor\"></path></g><defs><filter id=\"__filter0_b_5162_10628\" x=\"-35\" y=\"-35\" width=\"170\" height=\"170\" filterUnits=\"userSpaceOnUse\" color-interpolation-filters=\"sRGB\"><feFlood flood-opacity=\"0\" result=\"BackgroundImageFix\"></feFlood><feGaussianBlur in=\"BackgroundImageFix\" stdDeviation=\"17.5\"></feGaussianBlur><feComposite in2=\"SourceAlpha\" operator=\"in\" result=\"effect1_backgroundBlur_5162_10628\"></feComposite><feBlend mode=\"normal\" in=\"SourceGraphic\" in2=\"effect1_backgroundBlur_5162_10628\" result=\"shape\"></feBlend></filter><clipPath id=\"__clip0_5162_10628\"><rect width=\"40\" height=\"40\" fill=\"currentColor\" transform=\"translate(30 30)\"></rect></clipPath></defs>", 4)
  ])))
}
export default { render: render }